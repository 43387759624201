/**
 * Top Page
 * @class Top
 */

import kvSlider from '../modules/kvSlider.js';
import topicSlider from '../modules/topicSlider.js';
import BookSlider from '../modules/bookSlider.js';
import navFollow from '../modules/navFollow.js';

export default class Top {

  constructor() {
    const page = document.querySelector('.top');
    if (!page) return;
    this.init();
  }

  init() {
    new kvSlider();
    new topicSlider();
    new navFollow();
    new BookSlider();
  }

}
