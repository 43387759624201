export default class Qa {
  constructor() {
    const page = document.querySelector('.qa');
    this.list = document.querySelector('#qa-list');
    if (!page) return;

    // this.init();
  }

  init() {
    let anchors = {};
    const headings = [...document.querySelectorAll('.js-anchor-heading h2')];

    headings.forEach((el) => {
      const id = el.id;
      const hasCategory = el.getAttribute('data-has-category') !== 'false';
      const category = hasCategory && el.getAttribute('data-category') ? el.getAttribute('data-category') : 'Uncategorized';
      const text = el.textContent;

      if (!anchors[category]) anchors[category] = [];
      anchors[category].push({ id, text });
    });

    Object.keys(anchors).forEach((category) => {
      const ul = document.createElement('ul');
      ul.classList.add('btn-list', 'btn-list--col4', 'btn-list--qa');

      if (category !== 'Uncategorized') {
        const categoryHeader = document.createElement('p');
        categoryHeader.textContent = category;
        categoryHeader.classList.add('qa-category-name');
        this.list.appendChild(categoryHeader);
      }

      anchors[category].forEach((entry) => {
        const li = document.createElement('li');
        const a = document.createElement('a');
        a.textContent = entry.text;
        a.classList.add('category-btn', 'category-btn--anchor');
        a.href = `#${entry.id}`;

                // SVG Icon
                const arrowSpan = document.createElement('span');
                arrowSpan.classList.add('link-card__arrow');
                arrowSpan.innerHTML = `
                  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="down1" d="M9.89941 1.57812L9.86663 11.4449" stroke="#2C5FAC" style="stroke:#2C5FAC;stroke:color(display-p3 0.1745 0.3717 0.6730);stroke-opacity:1;" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                    <path id="down2" d="M3.86133 11.0352C3.86133 11.0352 5.82749 11.7526 7.27721 12.7857C8.72693 13.8188 9.85302 15.3052 9.85302 15.3052C9.85302 15.3052 10.9866 13.8113 12.4456 12.7686C13.9046 11.7258 15.8732 10.9953 15.8732 10.9953" stroke="#2C5FAC" style="stroke:#2C5FAC;stroke:color(display-p3 0.1745 0.3717 0.6730);stroke-opacity:1;" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                  </svg>
                `;

        a.appendChild(arrowSpan);
        li.appendChild(a);
        ul.appendChild(li);
      });

      this.list.appendChild(ul);
    });
  }
}
