/**
 * Common Page
 * @class Common
 */
import Accordion from "../modules/accordion";
import SmoothScroll from "../modules/smoothscroll";
import Fadein from "../modules/fadein";
import SimpleSlider from "../modules/simpleSlider.js";
import ThreeSlider from "../modules/threeSlider.js";
import StudySlider from '../modules/studySlider.js';

import BlockAccordion from '../modules/block-accordion.js';
import Toc from '../modules/toc.js';

import ScrollHintModule from '../modules/scrollHint.js';

export default class Common {

  constructor() {
    this.init();
  }

  init() {
    new Accordion();
    new SmoothScroll();
    new Fadein();
    new StudySlider();
    new SimpleSlider();
    new ThreeSlider();
    new BlockAccordion();
    this.currentActiveLink();
    new Toc();

    let scrollHint = new ScrollHintModule();
		if(scrollHint.target.length > 0) scrollHint.init();
  }

  currentActiveLink(){
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    let currentPath = currentUrl.pathname;

    // 連続する//,index.html　のパターンを削除
    currentPath = currentPath.replace(/\/\/+/g, '/');
    currentPath = currentPath.replace(/\/index\.html$/, '/');

    const links = document.querySelectorAll('a.header-nav__link');

    links.forEach(function(link) {
      const linkUrl = new URL(link.href);

      if (linkUrl.origin === currentDomain && linkUrl.pathname === currentPath) {
        link.classList.add('is-current');
        link.setAttribute('aria-current', 'page');
        link.setAttribute('tabindex', '-1');
      }
    });
  }

}
