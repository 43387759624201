import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
export default class Fadein {
  constructor() {
    this.init();
  }

  init() {
    this.fadeInAnimation();
  }

  fadeInAnimation() {
    const fadeTrigger = [...document.querySelectorAll('.gsap_fadein')];
    fadeTrigger.forEach((el) => {
      gsap.from(el, {
        opacity: 0,
        y: 30,
        duration: 0.89,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
        }
      });

    });
  }


}

