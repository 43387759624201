import Splide from '@splidejs/splide';

export default class TopicSlider {
  constructor() {
    const topicSlider = new Splide('#topics-slider', {
      type: 'loop',
      focus: 0,
      perMove: 1,
      perPage: 4,
      trimSpace: true,
      drag: false,
      autoplay: false,
      gap: '2.5rem',
      // speed: 900,
      pauseOnHover: false,
      pauseOnFocus: false,
      pagination: false,
    });

    // Ensure that the progress bar exists
    const bar = topicSlider.root.querySelector('.topics-slider__progress--bar');

    if (bar) {
      topicSlider.on('mounted move', function () {
        const end = topicSlider.Components.Controller.getEnd();
        const rate = Math.min((topicSlider.index) / end, 1);
        bar.style.width = `${rate * 100}%`;
      });
    } else {
      console.error('Progress bar element not found.');
    }

    topicSlider.mount();
  }
}
