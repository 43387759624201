export default class Toc {
  constructor() {
    this.init();
  }

  init() {
    const sidebar = document.querySelector(".sidebar");
    const mainColumn = document.querySelector(".page-head");
    const blockEditor = document.querySelector(".block-editor");
    const links = document.querySelectorAll(".service-nav--pc__link");
    const blocks = document.querySelectorAll(".block-accordion,.block-editor .study-tool .heading"); // .block-accordionと.headingを両方対象に
    if (mainColumn && sidebar && blocks.length > 0 && blockEditor) {
      // mainColumnの下端位置を取得
      const mainColumnBottom = mainColumn.offsetTop + mainColumn.offsetHeight;

      // blockEditorの位置を計算する関数
      const calculateBlockEditorPosition = () => {
        const top = blockEditor.offsetTop;
        const sidebarHeight = sidebar.offsetHeight;
        return {
          top: top - 80,
          bottom: top + blockEditor.offsetHeight - sidebarHeight - 0, // sidebar の高さを引く
        };
      };

      let { top: blockEditorTop, bottom: blockEditorBottom } = calculateBlockEditorPosition();

      // 各block-accordionと.headingの位置を取得する関数
      const updateBlockPositions = () => {
        return Array.from(blocks).map((block) => {
          const rect = block.getBoundingClientRect();
          return {
            element: block,
            top: window.scrollY + rect.top,
            bottom: window.scrollY + rect.top + rect.height,
          };
        });
      };

      let blockPositions = updateBlockPositions();

      // サイドバー固定処理とリンクの色変更をまとめた関数
      const updateSidebarAndLinks = () => {
        const scrollY = window.scrollY;

        // blockEditorの位置を再計算（アコーディオン開閉の影響を反映）
        ({ top: blockEditorTop, bottom: blockEditorBottom } = calculateBlockEditorPosition());

        // sidebarの固定処理
        if (scrollY > mainColumnBottom && scrollY >= blockEditorTop && scrollY < blockEditorBottom) {
          sidebar.classList.add("fixed");
        } else {
          sidebar.classList.remove("fixed");
        }

        // サービスリンクの色変更
        const midScroll = scrollY + window.innerHeight / 2; // 中央基準
        const firstBlockTop = blockPositions[0]?.top || 0; // 最初のブロックの位置

        if (midScroll >= firstBlockTop) {
          blockPositions.forEach((blockPos, index) => {
            if (midScroll >= blockPos.top && midScroll < blockPos.bottom) {
              links.forEach((link) => link.classList.remove("active"));
              if (links[index]) {
                links[index].classList.add("active");
              }
            }
          });
        } else {
          // 最初のブロックに到達していない場合、すべてのリンクからクラスを削除
          links.forEach((link) => link.classList.remove("active"));
        }
      };

      // スクロール時の処理を登録
      window.addEventListener("scroll", updateSidebarAndLinks);

      // Accordion開閉で高さを再計算する処理
      const accordionTriggers = document.querySelectorAll(".js-block-accordion-trigger");
      accordionTriggers.forEach((trigger) => {
        trigger.addEventListener("click", () => {
          setTimeout(() => {
            blockPositions = updateBlockPositions();
            updateSidebarAndLinks(); // 再計算後に状態を更新
          }, 300); // アニメーション終了後に再計算
        });
      });
    }
  }
}
