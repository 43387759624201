import Common from './pages/common.js';
import Top from './pages/top.js';
import Qa from './pages/qa.js';
import Header from './modules/header.js';
class App {
	constructor() {
		this.routes();
	}

	routes() {
		new Common();
		new Top();
    new Qa();
		new Header();
	}

}

window.App = new App();
