import Splide from '@splidejs/splide';

export default class BookSlider {
  constructor() {
    const bookSlider = new Splide('#book-slider', {
      type: 'loop',
      focus: 'center',
      perMove: 1,
      gap: '5%',
      ease: 'ease-in-out',
      autoplay: false,
      pagination: false,
      arrows: true,
      drag: false,
      perPage: 5,
      breakpoints: {
        1200: {
          perPage: 5,
        },
        1024: {
          perPage: 3,
        },
        768: {
          perPage: 2,
          gap: '5.12vw',
        },
      },
    });

    bookSlider.mount();
  }
}
