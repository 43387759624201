import Splide from '@splidejs/splide';

export default class ThreeSlider {
  constructor() {
    document.querySelectorAll('.three-slider .splide').forEach(function (el) {

      const slideCount = el.querySelectorAll('.splide__slide').length;

      // Function to determine loop setting
      const getLoopSetting = () => {
        let windowWidth = window.innerWidth;
        if ((windowWidth <= 768 && slideCount <= 1) || (windowWidth > 768 && slideCount <= 3)) {
          return 'slide';  // No loop
        }
        return 'loop';  // Loop in other cases
      };

      const getArrowSetting = () => {
        let windowWidth = window.innerWidth;
        if ((windowWidth <= 768 && slideCount <= 1) || (windowWidth > 768 && slideCount <= 3)) {
          return false;
        }
        return true;
      };

      // Function to determine drag setting
      const getDragSetting = () => {
        let windowWidth = window.innerWidth;
        if ((windowWidth <= 768 && slideCount <= 1) || (windowWidth > 768 && slideCount <= 3)) {
          return false;  // No loop
        }
        return true;  // Loop in other cases
      };

      // Function to determine focus setting
      const getFocusSetting = () => {
        let windowWidth = window.innerWidth;
        if ((windowWidth <= 768 && slideCount <= 1) || (windowWidth > 768 && slideCount <= 3)) {
          return '';  // No loop
        }
        return 'center';  // Loop in other cases
      };

      const simpleSlider = new Splide(el, {
        type: getLoopSetting(),
        focus: getFocusSetting(),  // Set focus dynamically
        perMove: 1,
        gap: '40px',
        padding: '20px',
        autoplay: false,
        pagination: false,
        arrows: getArrowSetting(),
        drag: getDragSetting(),  // Set drag dynamically
        perPage: 3,
        breakpoints: {
          768: {
            perPage: 1,
            gap: '3%',
            padding: '6%',
          },
        },
      });
      simpleSlider.mount();

      // Handle resize event
      window.addEventListener('resize', function () {
        const updatedType = getLoopSetting();
        const updatedArrow = getArrowSetting();
        const updatedDrag = getDragSetting();
        const updatedFocus = getFocusSetting();

        // Update the settings
        simpleSlider.options.type = updatedType;
        simpleSlider.options.drag = updatedDrag;
        simpleSlider.options.focus = updatedFocus;
        simpleSlider.options.arrows = updatedArrow;
        simpleSlider.refresh();  // Refresh slider to apply changes
      });
    });
  }
}
