
import Splide from '@splidejs/splide';
export default class kvSlider {

  constructor() {
      const kvSlider = new Splide('#kv-slider', {
        type: 'loop',
        perPage: 3,
        autoplay: true,
        interval: 6000,
        focus: 'center',
        gap: '1.2rem',
        // speed: 900,
        drag: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        resetProgress: false,
        pagination: true, // Ensure pagination is enabled
        classes: {
          pagination: 'kv__pagination',
          page: 'kv__pagination__page',
        }
      });
    
      const circumference = 2 * Math.PI * 14;
    
      kvSlider.on('mounted', function () {
        const paginationItems = document.querySelectorAll('.kv__pagination__page');
    
        paginationItems.forEach((item, index) => {
          const itemNum = `0${index + 1}`;
          item.innerHTML = itemNum;
    
          const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          svg.setAttribute('class', 'progress-ring');
          svg.setAttribute('width', '30');
          svg.setAttribute('height', '30');
          svg.setAttribute('viewBox', '0 0 30 30');
    
          const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
          circle.setAttribute('class', 'progress-ring__circle');
          circle.setAttribute('stroke-width', '1');
          circle.setAttribute('fill', 'transparent');
          circle.setAttribute('r', '14');
          circle.setAttribute('cx', '15');
          circle.setAttribute('cy', '15');
    
          svg.appendChild(circle);
          item.appendChild(svg);
        });
    
        document.querySelectorAll('.progress-ring__circle').forEach(circle => {
          circle.style.strokeDasharray = `${circumference} ${circumference}`;
          circle.style.strokeDashoffset = circumference;
        });
      });
    
      kvSlider.on('autoplay:playing', function (rate) {
        const activeButton = document.querySelector('.kv__pagination__page.is-active');
        if (activeButton) {
          const circle = activeButton.querySelector('.progress-ring__circle');
          if (circle) {
            const offset = circumference - (rate * circumference);
            circle.style.strokeDashoffset = offset;
          }
        }
      });
    
      kvSlider.on('moved', function () {
        document.querySelectorAll('.progress-ring__circle').forEach(circle => {
          circle.style.strokeDashoffset = circumference;
        });
      });
    
      kvSlider.mount();
  }
}

