import Splide from '@splidejs/splide';

export default class SimpleSlider {
  constructor() {
    this.sliders = []; // スライダーインスタンスを格納する配列

    document.querySelectorAll('.simple-slider .splide').forEach((el) => {
      const slideCount = el.querySelectorAll('.splide__slide').length;

      // スライド数が条件を満たさない場合、スライダーを初期化せず通常表示用のスタイルを適用
      if (slideCount < 3) {
        el.classList.add('slider-static'); // 通常表示用のクラスを追加
        el.style.visibility = 'visible'; // スライダーを表示させる
        return; // スライダーの初期化をスキップ
      }

      // スライダーの初期設定
      const simpleSlider = new Splide(el, {
        type: 'loop',
        perMove: 1,
        autoplay: false,
        pagination: false,
        arrows: true,
        drag: true,
        perPage: 3,
        padding: '100px',
        gap: '20px',
        breakpoints: {
          768: {
            perPage: 1,
            gap: '6%',
            padding: '7%',
          },
        },
      });

      // スライダーのインスタンスを配列に保存
      this.sliders.push({ el, instance: simpleSlider });

      simpleSlider.mount();

      // ウィンドウサイズ変更時のイベントリスナー
      window.addEventListener('resize', () => this.handleResize(simpleSlider, slideCount));
    });
  }

  // スライダーを無効化するメソッド
  destroySlider() {
    this.sliders.forEach(({ instance }) => {
      instance.destroy(); // スライダーインスタンスを無効化
    });
    this.sliders = []; // インスタンス配列をクリア
  }

  // リサイズイベント時にスライダーを破棄または再設定
  handleResize(simpleSlider, slideCount) {
    if (slideCount < 2) {
      simpleSlider.destroy(); // スライダーを破棄
    } else {
      simpleSlider.refresh(); // スライダーを再設定
    }
  }
}
