import { gsap } from 'gsap';

export default class Header {
  constructor() {
    this.currentY = 0;
    this.navFlag = false;
    this.searchModalFlag = false;
    this.hamburger = document.querySelector('.js-gnav-trigger');
    this.searchModalTriggers = document.querySelectorAll('.search-modal-trigger');
    this.searchModal = document.querySelector('#search-modal');
    this.searchButton = document.querySelector('.gnav .mt-site-search-form__button');
    this.body = document.querySelector('body');
    this.init();
  }

  init() {
    // ハンバーガーメニューのトグル
    this.hamburger.addEventListener('click', this.toggleMenu.bind(this));

    // 検索モーダルトリガーの設定
    if (this.searchModalTriggers) {
      this.searchModalTriggers.forEach((trigger) => {
        trigger.addEventListener('click', this.toggleSearchModal.bind(this));
      });
    }
    if (this.searchButton) {

      this.searchButton.addEventListener('click', this.toggleMenu.bind(this));
    }

    const overlayBg = this.searchModal.querySelector('.overlay__bg');
    if (overlayBg) {
      overlayBg.addEventListener('click', this.toggleSearchModal.bind(this));
    }

    const spSearchModalTriggers = document.querySelector('.sp-tool .search-modal-trigger');
    if (spSearchModalTriggers) {
      spSearchModalTriggers.addEventListener('click', this.closeMenu.bind(this));
    }

    this.navHasSubs = [...document.querySelectorAll('[data-subnav-has]')];
    this.navsubs = [...document.querySelectorAll('[data-subnav]')];

    [...this.navHasSubs, ...this.navsubs].forEach(item => {
      item.addEventListener('mouseover', this.navsubMouseover.bind(this));
      item.addEventListener('mouseleave', this.navsubMouseleave.bind(this));
      // item.addEventListener('focus', this.navsubMouseover.bind(this));
      // item.addEventListener('blur', this.navsubMouseleave.bind(this));
    });
  }

  toggleMenu() {
    if (!this.navFlag) {
      this.currentY = window.scrollY || window.pageYOffset;
      this.navFlag = true;
      this.hamburger.classList.add('active');
      this.body.classList.add('is-toggle-open');
    } else {
      this.hamburger.classList.remove('active');
      this.body.classList.remove('is-toggle-open');
      this.navFlag = false;
      window.scrollTo(0, this.currentY);
    }

    if (this.searchModalFlag) {
      this.searchModalFlag = false;
      this.searchModalTriggers.forEach((trigger) => {
        trigger.classList.remove('active');
      });
      this.searchModal.classList.remove('is-toggle-open');
      window.scrollTo(0, this.currentY);
    }

    window.addEventListener('scroll', () => {
      if (this.navFlag) {
        window.scrollTo(0, this.currentY);
      }
    }
    );
  }

  closeMenu() {
    if (this.navFlag) {
      this.hamburger.classList.remove('active');
      this.body.classList.remove('is-toggle-open');
      this.navFlag = false;
      window.scrollTo(0, this.currentY);
    }
  }

  toggleSearchModal() {
    this.currentY = window.scrollY || window.pageYOffset;

    if (!this.searchModalFlag) {
      this.searchModalFlag = true;
      this.searchModalTriggers.forEach((trigger) => {
        trigger.classList.add('active');
      });
      this.searchModal.classList.add('is-toggle-open');
    } else {
      this.searchModalFlag = false;
      this.searchModalTriggers.forEach((trigger) => {
        trigger.classList.remove('active');
      });
      this.searchModal.classList.remove('is-toggle-open');
      window.scrollTo(0, this.currentY);
    }
  }




    //mouseover
    navsubMouseover(e) {
      let ctarget = e.currentTarget;
      let btns = this.getElement(ctarget, 'btn');
      let nav = this.getElement(ctarget);
      ctarget.classList.add('is-active');
      nav.classList.add('is-active');

      if (ctarget.dataset.subnav) {
        btns.classList.add('is-active');
      }
      gsap.killTweensOf(nav);
      gsap.to(nav, {
        duration: 0.0,
        autoAlpha: 1,
        // delay:.1,
        ease: "power4.out",
        // y:0,
        // zIndex: -1,
      });
    }
    navsubMouseleave(e) {
      let ctarget = e.currentTarget;
      let btns = this.getElement(ctarget, 'btn');
      let nav = this.getElement(ctarget);
      // this.headerBg.classList.remove('active');
      gsap.killTweensOf(nav);
      btns.classList.remove('is-active');
      nav.classList.remove('is-active');
      gsap.to(nav, {
        duration: .0,
        autoAlpha: 0,
        ease: "power4.out",
      })
    }


  getElement(target, type = null) {
    let closestHeader = target.closest(".header");
    let cat = target.getAttribute('data-subnav-has')||target.getAttribute('data-subnav');
    switch (type) {
      case 'btn':
        var ele = closestHeader.querySelector(`[data-subnav-has="${cat}"]`);
        break;
      default:
        var ele = closestHeader.querySelector(`[data-subnav="${cat}"]`);
        break;
    }
    return ele;
  }


}
