export default class NavFollow {
  constructor() {
    this.section = document.querySelector('.service-pc');
    this.nav = document.querySelector('.service-nav--pc');
    this.navLinks = document.querySelectorAll('.service-nav--pc__link');
    this.sections = Array.from(this.navLinks).map(link => document.querySelector(link.getAttribute('href')));

    if (this.section && this.nav && this.navLinks.length > 0) {
      this.init();
    }
  }

  init() {
    this.updateActiveLink();
    window.addEventListener('scroll', () => {
      this.updateNavPosition();
      this.updateActiveLink();
    });
    window.addEventListener('resize', () => {
      this.updateNavPosition();
      this.updateActiveLink();
    });
  }

  updateNavPosition() {
    const sectionRect = this.section.getBoundingClientRect();
    const navHeight = this.nav.offsetHeight;

    if (sectionRect.top <= 190 && sectionRect.bottom >= navHeight + 190) {
      // スクロール中
      this.nav.style.position = 'fixed';
      this.nav.style.top = '285px';
      this.nav.style.bottom = 'auto';
    } else if (sectionRect.top > 90) {
      // トップ
      this.nav.style.position = 'absolute';
      this.nav.style.top = '90px';
      this.nav.style.bottom = 'auto';
    } else if (sectionRect.bottom < navHeight + 90) {
      // ボトム
      this.nav.style.position = 'absolute';
      this.nav.style.top = 'auto';
      this.nav.style.bottom = '0px';
    }
  }

  updateActiveLink() {
    let activeFound = false;

    this.sections.forEach((section, index) => {
      const sectionRect = section.getBoundingClientRect();
      const navLink = this.navLinks[index];

      if (!activeFound && sectionRect.top <= 90 && sectionRect.bottom >= 90) {
        navLink.classList.add('active');
        activeFound = true;
      } else {
        navLink.classList.remove('active');
      }
    });

    // デフォルトで最初のリンクをアクティブにする
    if (!activeFound) {
      this.navLinks[0].classList.add('active');
    }
  }
}
