import Splide from '@splidejs/splide';

export default class StudySlider {
  constructor() {

    document.querySelectorAll('.study-tool .splide').forEach( (el) => {
      // スライドの数をチェック
      const slideCount = el.querySelectorAll('.splide__slide').length;

      this.splideTrack = el.querySelector('.study-tool .splide__track');
      this.progressBarContainer = el.querySelector('.study-tool .progress-bar-container');

      // ループを動的に設定する関数
      const getLoopSetting = () => {
        const windowWidth = window.innerWidth;
        // 768px以下かつスライドが2枚以下、または769px以上かつスライドが4枚の場合にループしない
        if ((windowWidth <= 768 && slideCount <= 2) || (windowWidth > 768 && slideCount <= 3)) {
          this.splideTrack.style.marginRight = '0';
          this.progressBarContainer.style.display = 'none';
          return 'slide';  // ループしない
        }
        return 'loop';  // それ以外の場合はループ
      };

      // 初期化オプション
      const options = {
        type: getLoopSetting(),  // 動的にループ設定を決定
        perMove: 1,
        gap: '50px',
        autoplay: false,
        pagination: false,
        arrows: true,
        drag: false,
        perPage: 4.7,  // 固定（変更しない）
        breakpoints: {
          768: {
            perPage: 2,  // 768px以下では2枚を表示
            gap: '2%',
          },
        },
      };

      const bookSlider = new Splide(el, options);

      // プログレスバーの更新
      const bar = bookSlider.root.querySelector('.topics-slider__progress--bar');

      if (bar) {
        bookSlider.on('mounted move', function () {
          const end = bookSlider.Components.Controller.getEnd();
          const index = bookSlider.Components.Controller.getIndex();
          const rate = Math.min(index / end, 1);
          bar.style.width = `${rate * 100}%`;
        });
      } else {
        console.error('Progress bar element not found.');
      }

      bookSlider.mount();

      // 画面サイズの変更に対応
      window.addEventListener('resize', function () {
        const updatedType = getLoopSetting();  // リサイズ後のループ設定を決定

        // 設定を更新（perPageは変更せず、typeだけ変更）
        bookSlider.options.type = updatedType;

        bookSlider.refresh();  // スライダーを再描画して反映
      });
    });
  }
}
