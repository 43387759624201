export default class Accordion {
  constructor() {
    this.accordions = [];
    this.init();
  }

  init() {
    this.accordions = document.querySelectorAll('.accordion');
    this.setupEventListeners();
  }

  setupEventListeners() {
    this.accordions.forEach(accordion => {
      const btn = accordion.firstElementChild;
      const content = accordion.querySelector('.accordion__content');

      // ARIA attributes 設定
      btn.setAttribute('aria-expanded', 'false');
      content.setAttribute('aria-hidden', 'true');

      btn.addEventListener('click', () => this.toggleAccordion(accordion));
    });
  }

  toggleAccordion(clicked) {
    const btn = clicked.firstElementChild;
    const content = clicked.querySelector('.accordion__content');
    const isActive = clicked.classList.contains('is-active');

    this.accordions.forEach(accordion => {
      accordion.classList.remove('is-active');
      accordion.firstElementChild.setAttribute('aria-expanded', 'false');
      accordion.querySelector('.accordion__content').setAttribute('aria-hidden', 'true');
      accordion.querySelector('.accordion__content').style.maxHeight = null;
    });

    if (!isActive) {
      clicked.classList.add('is-active');
      btn.setAttribute('aria-expanded', 'true');
      content.setAttribute('aria-hidden', 'false');
      content.style.maxHeight = `${content.scrollHeight}px`;
    }
  }
}
