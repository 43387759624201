// JavaScriptライブラリ ScrollHint
// DOWNLOAD 24.12.5
// 横スクロール要素に属性[data-scrollable]を付与してください。
import ScrollHint from 'scroll-hint';

export default class ScrollHintModule  {
  constructor() {
    this.target = document.querySelectorAll('[data-scrollable]');
  }
  init() {
    this.scrollHintInstance = new ScrollHint('[data-scrollable]', {
      // suggestiveShadow: true, // Example option
      i18n: {
        scrollable: '横にスクロールできます'
      }
    });
  }
}
